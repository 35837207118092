// File: PageHome.jsx

import React from "react";
import { useTranslation } from "react-i18next";
import "./PageHome.css";

const PageHome = () => {
  const { t } = useTranslation();

  return (
    <div className="home-wrapper">
      {/* HERO / BANNER SECTION */}
      <section
        className="hero-section"
        style={{
          background:
            "url('/images/spiritual_way_hero.jpg') center / cover no-repeat",
        }}
      >
        <div className="hero-text">
          <h1>{t("hero_title")}</h1>
          <p>{t("hero_subtitle")}</p>
        </div>
        <div className="hero-cta">
          <a href="/register" className="cta-button">
            {t("hero_cta_text")}
          </a>
        </div>
        {/* Optional background image or illustration */}
      </section>

      {/* FEATURES SECTION */}
      <section className="features-section">
        <h2>{t("features_title")}</h2>
        <p className="section-intro">{t("features_intro")}</p>
        <div className="features-list">
          <div className="feature-item">
            <i className="feature-icon fa fa-heart"></i>
            <h3>{t("feature_item1_title")}</h3>
            <p>{t("feature_item1_description")}</p>
          </div>
          <div className="feature-item">
            <i className="feature-icon fa fa-user"></i>
            <h3>{t("feature_item2_title")}</h3>
            <p>{t("feature_item2_description")}</p>
          </div>
          <div className="feature-item">
            <i className="feature-icon fa fa-star"></i>
            <h3>{t("feature_item3_title")}</h3>
            <p>{t("feature_item3_description")}</p>
          </div>
          {/* <div className="feature-item">
            <i className="feature-icon fa fa-star"></i>
            <h3>{t("feature_item4_title")}</h3>
            <p>{t("feature_item4_description")}</p>
          </div> */}
        </div>
      </section>

      {/* TESTIMONIAL SECTION */}
      {/* <section className="testimonials-section">
        <h2>{t("testimonials_title")}</h2>
        <div className="testimonials-list">
          <div className="testimonial-item">
            <p className="testimonial-text">{t("testimonial1_text")}</p>
            <p className="testimonial-user">{t("testimonial1_user")}</p>
          </div>
          <div className="testimonial-item">
            <p className="testimonial-text">{t("testimonial2_text")}</p>
            <p className="testimonial-user">{t("testimonial2_user")}</p>
          </div>
          <div className="testimonial-item">
            <p className="testimonial-text">{t("testimonial3_text")}</p>
            <p className="testimonial-user">{t("testimonial3_user")}</p>
          </div>
          <div className="testimonial-item">
            <p className="testimonial-text">{t("testimonial4_text")}</p>
            <p className="testimonial-user">{t("testimonial4_user")}</p>
          </div>
        </div>
      </section> */}

      {/* CALL TO ACTION SECTION */}
      <section className="cta-section">
        <h2>{t("cta_title")}</h2>
        <p>{t("cta_description")}</p>
        <a href="/register" className="cta-button cta-button-secondary">
          {t("cta_button_text")}
        </a>
      </section>

      {/* FOOTER SECTION */}
      <footer className="footer-section">
        <p>{t("footer_text")}</p>
      </footer>
    </div>
  );
};

export default PageHome;
