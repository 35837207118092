import React, { useEffect, useState } from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import api from "../../../services/api";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, ChartDataLabels);

const CircumplexChart = ({ dataUpdated }) => {
  const [data, setData] = useState([]);
  const [viewMode, setViewMode] = useState("week");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/mood-energy/states");
        const states = response.data;

        let filteredStates;

        if (viewMode === "day") {
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate.toDateString() === selectedDate.toDateString();
          });
        } else if (viewMode === "week") {
          const startOfWeek = new Date(selectedDate);
          startOfWeek.setDate(startOfWeek.getDate() - 6);
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date(selectedDate);
          endOfWeek.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfWeek && stateDate <= endOfWeek;
          });
        } else if (viewMode === "month") {
          const startOfMonth = new Date(selectedDate);
          startOfMonth.setDate(startOfMonth.getDate() - 29);
          startOfMonth.setHours(0, 0, 0, 0);
          const endOfMonth = new Date(selectedDate);
          endOfMonth.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfMonth && stateDate <= endOfMonth;
          });
        } else if (viewMode === "year") {
          const startOfYear = new Date(selectedDate.getFullYear(), 0, 1);
          const endOfYear = new Date(selectedDate.getFullYear(), 11, 31);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfYear && stateDate <= endOfYear;
          });
        }

        // Filtteri poistetuille emojeille ja vanhoille merkinnöille (valence = 5)
        const filteredEmojis = ["😴", "🤔"];
        filteredStates = filteredStates.filter((state) => !filteredEmojis.includes(state.emoji) && state.valence !== 5);

        const chartData = filteredStates.map((state) => ({
          x: getValence(state.emoji, state.valence),
          y: getArousal(state.energy),
          emoji: state.emoji,
          mood: state.mood
        }));

        setData(chartData);
      } catch (error) {
        console.error("Error fetching mood and energy states:", error);
      }
    };

    fetchData();
  }, [viewMode, selectedDate, dataUpdated]);

  const getValence = (emoji, valence) => {
    const positiveEmojis = ["😊", "😂", "😇", "🤩", "🥳", "😌"];
    const negativeEmojis = ["😢", "😡", "😨", "😞", "😳", "🥴", "😬", "🥵", "😟", "😰", "🤢", "😢"];

    if (positiveEmojis.includes(emoji)) {
      //console.log("positive", valence);
      return valence;
    }
      
    if (negativeEmojis.includes(emoji)) {
      //console.log("negative", valence);
      return -1 * valence;
    }
    //console.log("neutral", valence);
    return 0;
  };

  const getArousal = (energy) => {
    return energy  - 5; // Scale energy to [-4, 4]
  };

  const chartData = {
    datasets: [
      {
        label: t("arousal_valence"),
        data: data,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        pointRadius: 5,
      },
    ],
  };

  // Chart min and max values
  const min = -5.2;
  const max = 5.2;

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: t("valence"),
        },
        min: min, 
        max: max, 
        grid: {
          drawOnChartArea: true,
          drawTicks: false,
          drawBorder: false,
          color: (context) => {
            if(context.tick.value === 0)
              return 'black';
            else if(context.tick.value === max || context.tick.value === min)
              return '#e0e0e0';
            },
        },
        ticks: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: t("arousal"),
        },
        min: min,
        max: max,
        grid: {
          drawOnChartArea: true,
          drawTicks: false,
          drawBorder: false,
          color: (context) => {
            if(context.tick.value === 0)
              return 'black';
            else if(context.tick.value === max || context.tick.value === min)
              return '#e0e0e0';
            },
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function(context) {
            const { x, y, emoji, mood } = context.raw;
            return [
              `${t("mood")}: ${emoji}`,
              `${t("valence")}: ${x}`,
              `${t("arousal")}: ${y}`,
              `${t("note")}: ${mood}`
            ];
          }
        }
      },
      datalabels: {
        display: false, //hide x,y value labels
        formatter: (value, context) => {
          return `(${value.x}, ${value.y})`;
        },
        color: 'black',
        anchor: 'end',
        align: 'top',
      },
      customLabels: {
        id: 'customLabels',
        beforeDraw: (chart) => {
          const { ctx, chartArea: { left, right, top, bottom }, scales: { x, y } } = chart;

          // X-akselin custom labelit
          ctx.save();
          ctx.font = '12px Arial';
          ctx.fillStyle = 'gray';
          ctx.textAlign = 'center';
          ctx.fillText(t('unpleasant'), left + 40, bottom + 10);
          ctx.fillText(t('pleasant'), right - 40, bottom + 10);
          ctx.restore();

          // Y-akselin custom labelit
          ctx.save();
          ctx.font = '12px Arial';
          ctx.fillStyle = 'gray';
          ctx.textAlign = 'center';
          ctx.translate(left - 10, top + 20);
          ctx.rotate(-Math.PI / 2);
          ctx.fillText(t('high'), 0, 0);
          ctx.restore();

          ctx.save();
          ctx.font = '12px Arial';
          ctx.fillStyle = 'gray';
          ctx.textAlign = 'center';
          ctx.translate(left - 10, bottom - 20);
          ctx.rotate(-Math.PI / 2);
          ctx.fillText(t('low'), 0, 0);
          ctx.restore();
        }
      }
    }
  };

  return (
    <div className="mood-energy-chart-container">
      <h2>{t("arousal_valence")}</h2>
      <p>{t("arousal_note")}</p>
      <div className="date-picker-container">
        <label htmlFor="date-picker">{t("choose_date")}</label>
        <DatePicker
          id="date-picker"
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd.MM.yyyy"
          className="date-picker"
        />
      </div>
      <div className="view-mode-buttons">
        <button
          onClick={() => setViewMode("day")}
          className={viewMode === "day" ? "active" : ""}
        >
          {t("day")}
        </button>
        <button
          onClick={() => setViewMode("week")}
          className={viewMode === "week" ? "active" : ""}
        >
          {t("week")}
        </button>
        <button
          onClick={() => setViewMode("month")}
          className={viewMode === "month" ? "active" : ""}
        >
          {t("month")}
        </button>
        <button
          onClick={() => setViewMode("year")}
          className={viewMode === "year" ? "active" : ""}
        >
          {t("year")}
        </button>
      </div>
      <div className="chart-overlay-container">
        <div className="scatter-chart">
          <Scatter data={chartData} options={options} plugins={[options.plugins.customLabels]} />
        </div>
      </div>
    </div>
  );
};

export default CircumplexChart;