import React, { useEffect, useState } from "react";
import { Scatter } from "react-chartjs-2";
import { Chart as ChartJS, LinearScale, PointElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import api from "../../../services/api";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(LinearScale, PointElement, Tooltip, Legend, ChartDataLabels);

const CircumplexChart = ({ dataUpdated }) => {
  const [data, setData] = useState([]);
  const [viewMode, setViewMode] = useState("week");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { t } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/mood-energy/states");
        const states = response.data;

        let filteredStates;

        if (viewMode === "day") {
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate.toDateString() === selectedDate.toDateString();
          });
        } else if (viewMode === "week") {
          const startOfWeek = new Date(selectedDate);
          startOfWeek.setDate(startOfWeek.getDate() - 6); // Edelliset 7 päivää
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date(selectedDate);
          endOfWeek.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfWeek && stateDate <= endOfWeek;
          });
        } else if (viewMode === "month") {
          const startOfMonth = new Date(selectedDate);
          startOfMonth.setDate(startOfMonth.getDate() - 29); // Edelliset 30 päivää
          startOfMonth.setHours(0, 0, 0, 0);
          const endOfMonth = new Date(selectedDate);
          endOfMonth.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfMonth && stateDate <= endOfMonth;
          });
        } else if (viewMode === "year") {
          const startOfYear = new Date(selectedDate.getFullYear(), 0, 1);
          const endOfYear = new Date(selectedDate.getFullYear(), 11, 31);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfYear && stateDate <= endOfYear;
          });
        }

        // Filtteri poistetuille emojeille ja vanhoille merkinnöille (valence = 5)
        const filteredEmojis = ["😴", "🤔"];
        filteredStates = filteredStates.filter((state) => !filteredEmojis.includes(state.emoji) && state.valence !== 5);
        
        const groupedData = {};
        filteredStates.forEach((state) => {
          const valence = getValence(state.emoji);
          const arousal = getArousal(state.energy);
          const key = `${valence},${arousal}`;
          if (!groupedData[key]) {
            groupedData[key] = { x: valence, y: arousal, count: 0 };
          }
          groupedData[key].count += 1;
        });

        const chartData = Object.values(groupedData).map((d) => ({
          x: d.x,
          y: d.y,
          count: d.count,
        }));

        setData(chartData);
      } catch (error) {
        console.error("Error fetching mood and energy states:", error);
      }
    };

    fetchData();
  }, [viewMode, selectedDate, dataUpdated]);

  const getValence = (emoji) => {
    const positiveEmojis = ["😊", "😂", "😇", "🤩", "🥳", "😌"];
    const negativeEmojis = ["😢", "😡", "😨", "😞", "😳", "🥴", "😬", "🥵", "😟", "😰", "🤢", "😢"];

    if (positiveEmojis.includes(emoji)) return 1;
    if (negativeEmojis.includes(emoji)) return -1;
    return 0;
  };

  const getArousal = (energy) => {
    if (energy >= 6) return 1;
    if (energy <= 4) return -1;
    return 0;
  };

  const totalPoints = data.reduce((sum, d) => sum + d.count, 0);

  const chartData = {
    datasets: [
      {
        label: t("arousal_valence"),
        data: data,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        pointRadius: 0,
      },
    ],
  };

  // Chart min and max values
  const min = -2.5;
  const max = 2.5;

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
      }
    },
    scales: {
      x: {
        type: "linear",
        position: "bottom",
        title: {
          display: true,
          text: t("valence"),
        },
        min: min,
        max: max,
        grid: {
          drawOnChartArea: true,
          drawTicks: false,
          drawBorder: false,
          color: (context) => {
            if(context.tick.value === 0)
              return 'white';
            else if(context.tick.value === max || context.tick.value === -min)
              return '#e0e0e0';
            },
        },
        ticks: {
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: t("arousal"),
        },
        min: min,
        max: max,
        grid: {
          drawOnChartArea: true,
          drawTicks: false,
          drawBorder: false,
          color: (context) => {
            if(context.tick.value === 0)
              return 'white';
            else if(context.tick.value === max || context.tick.value === min)
              return '#e0e0e0';
            },
        },
        ticks: {
          display: false, // Piilota mitta-asteikko
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            const percentage = ((context.raw.count / totalPoints) * 100).toFixed(2);
            return `${context.raw.count} (${percentage}%)`;
          }
        }
      },
      datalabels: {
        display: true,
        formatter: (context) => {
          const percentage = ((context.count / totalPoints) * 100).toFixed(2);
          return `${percentage}%`;
        },
        color: 'black',
        anchor: 'end',
        align: 'top',
      },
      customLabels: {
        id: 'customLabels',
        beforeDraw: (chart) => {
          const { ctx, chartArea: { left, right, top, bottom }, scales: { x, y } } = chart;

           // Piirrä ympyrä
          const centerX = (left + right) / 2;
          const centerY = (top + bottom) / 2;
          const radius = Math.min(right - left, bottom - top) / 2 - 5; // 5px marginaali

          ctx.save();
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
          ctx.clip();

          // Piirrä taustavärit neljälle lohkolle
          ctx.fillStyle = 'rgba(255, 0, 0, 0.1)'; // Punainen lohko
          ctx.fillRect(left, top, centerX - left, centerY - top);

          ctx.fillStyle = 'rgba(0, 255, 0, 0.1)'; // Vihreä lohko
          ctx.fillRect(centerX, top, right - centerX, centerY - top);

          ctx.fillStyle = 'rgba(0, 0, 255, 0.1)'; // Sininen lohko
          ctx.fillRect(left, centerY, centerX - left, bottom - centerY);

          ctx.fillStyle = 'rgba(255, 255, 0, 0.1)'; // Keltainen lohko
          ctx.fillRect(centerX, centerY, right - centerX, bottom - centerY);
          ctx.restore();

          // Custom labelit tietyissä koordinaateissa
          const x1 = x.getPixelForValue(-1);
          const y1 = y.getPixelForValue(1.5);
          const y2 = y.getPixelForValue(-1);
          const x2 = x.getPixelForValue(1);
          const x3 = x.getPixelForValue(0);
          const y3 = y.getPixelForValue(0.5);

          ctx.save();
          ctx.font = 'bold 12px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
      
          // Tarkista näytön leveys ja säädä tekstien sijaintia ja kokoa
          const screenWidth = window.innerWidth;
          if (screenWidth < 480) {
            ctx.font = 'bold 10px Arial';
            ctx.fillText(t('negative_arousal'), x1, y1 + 30);
            ctx.fillText(t('negative_low_arousal'), x1 - 5, y2);
            ctx.fillText(t('positive_arousal'), x2, y1 + 30);
            ctx.fillText(t('positive_low_arousal'), x2 + 5, y2);
            ctx.fillText(t('neutral_arousal'), x3, y3 + 35);
          } else {
            ctx.fillText(t('negative_arousal'), x1 + 10, y1);
            ctx.fillText(t('negative_low_arousal'), x1, y2);
            ctx.fillText(t('positive_arousal'), x2 - 10, y1);
            ctx.fillText(t('positive_low_arousal'), x2, y2);
            ctx.fillText(t('neutral_arousal'), x3, y3);
          }
          ctx.restore();

          // Piirrä ympyrä
          ctx.save();
          ctx.beginPath();
          ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI);
          ctx.strokeStyle = 'gray';
          ctx.stroke();
          ctx.restore();

          // Piirrä ympyrän jakavat viivat
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(centerX, top);
          ctx.lineTo(centerX, bottom);
          ctx.moveTo(left, centerY);
          ctx.lineTo(right, centerY);
          ctx.strokeStyle = 'gray';
          ctx.stroke();
          ctx.restore();

        }
      }
    }
  };

  return (
    <div className="mood-energy-chart-container">
      <h2>{t("arousal_valence_percentage")}</h2>
      <p>{t("arousal_note")}</p>
      <div className="date-picker-container">
        <label htmlFor="date-picker">{t("choose_date")}</label>
        <DatePicker
          id="date-picker"
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd.MM.yyyy"
          className="date-picker"
        />
      </div>
      <div className="view-mode-buttons">
        <button
          onClick={() => setViewMode("day")}
          className={viewMode === "day" ? "active" : ""}
        >
          {t("day")}
        </button>
        <button
          onClick={() => setViewMode("week")}
          className={viewMode === "week" ? "active" : ""}
        >
          {t("week")}
        </button>
        <button
          onClick={() => setViewMode("month")}
          className={viewMode === "month" ? "active" : ""}
        >
          {t("month")}
        </button>
        <button
          onClick={() => setViewMode("year")}
          className={viewMode === "year" ? "active" : ""}
        >
          {t("year")}
        </button>
      </div>
      <div className="chart-overlay-container">
        <div className="scatter-chart">
          <Scatter data={chartData} options={options} plugins={[options.plugins.customLabels]} />
        </div>
      </div>
    </div>
  );
};

export default CircumplexChart;