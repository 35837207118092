import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { jwtDecode } from "jwt-decode"; // Oikea tuontimuoto nimettynä vientinä
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./MoodEnergyTracking.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import api from "../../../services/api";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartDataLabels
);

const MoodEnergySummaryChart = ({ dataUpdated, onDataUpdate }) => {
  const [chartData, setChartData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewMode, setViewMode] = useState("day"); // Lisää viewMode-tila
  const [emojiImages, setEmojiImages] = useState([]);

  const userLocale = navigator.language || "fi-FI";
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Function to delete the last entry
  const deleteLastEntry = async () => {
    setErrorMessage("");
    setMessage("");

    const confirmDelete = window.confirm(t("confirm_delete_last_entry"));
    if (!confirmDelete) {
      return;
    }

    try {
      const response = await api.delete("/mood-energy/last-entry");

      if (response.status === 200) {
        setMessage(t("last_entry_deleted"));
        if (onDataUpdate) onDataUpdate();      
        setTimeout(() => {
          setMessage("");
        }, 3000);
      } else {
        setErrorMessage(t("delete_last_entry_failed"));
      }
    } catch (error) {
      console.error("Error deleting last entry:", error);
      setErrorMessage(t("delete_last_entry_failed"));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/mood-energy/states");

        const states = response.data;

        // Filter data based on view mode
        let filteredStates;
/*         if (viewMode === "day") {
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate.toDateString() === selectedDate.toDateString();
          });
        } else if (viewMode === "week") {
          const startOfWeek = new Date(selectedDate);
          startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date(startOfWeek);
          endOfWeek.setDate(endOfWeek.getDate() + 6);
          endOfWeek.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfWeek && stateDate <= endOfWeek;
          });
        } else if (viewMode === "month") {
          const startOfMonth = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            1
          );
          const endOfMonth = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth() + 1,
            0
          );
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfMonth && stateDate <= endOfMonth;
          });
        } else if (viewMode === "year") {
          const startOfYear = new Date(selectedDate.getFullYear(), 0, 1);
          const endOfYear = new Date(selectedDate.getFullYear(), 11, 31);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfYear && stateDate <= endOfYear;
          });
        } */
        if (viewMode === "day") {
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate.toDateString() === selectedDate.toDateString();
          });
        } else if (viewMode === "week") {
          const startOfWeek = new Date(selectedDate);
          startOfWeek.setDate(startOfWeek.getDate() - 6); // Edelliset 7 päivää
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date(selectedDate);
          endOfWeek.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfWeek && stateDate <= endOfWeek;
          });
        } else if (viewMode === "month") {
          const startOfMonth = new Date(selectedDate);
          startOfMonth.setDate(startOfMonth.getDate() - 29); // Edelliset 30 päivää
          startOfMonth.setHours(0, 0, 0, 0);
          const endOfMonth = new Date(selectedDate);
          endOfMonth.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfMonth && stateDate <= endOfMonth;
          });
        } else if (viewMode === "year") {
          const startOfYear = new Date(selectedDate.getFullYear(), 0, 1);
          const endOfYear = new Date(selectedDate.getFullYear(), 11, 31);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfYear && stateDate <= endOfYear;
          });
        }

        const dates = filteredStates.map((state) => new Date(state.created_at));
        const moods = filteredStates.map((state) => state.mood);
        const energies = filteredStates.map((state) => state.energy);
        const emojis = filteredStates.map((state) => state.emoji);

        // Convert emojis to images
        const emojiImages = emojis.map((emoji) => {
          const img = new Image();
          img.src = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"><text x="0" y="24" font-size="24">${emoji}</text></svg>`;
          return img;
        });

        setEmojiImages(emojiImages);

        setChartData({
          labels: dates,
          datasets: [
            {
              label: t("energy_level"),
              data: energies,
              fill: false,
              borderColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  return null;
                }

                const gradient = ctx.createLinearGradient(
                  0,
                  chartArea.bottom,
                  0,
                  chartArea.top
                );
                gradient.addColorStop(0, "blue");
                gradient.addColorStop(0.5, "green");
                gradient.addColorStop(1, "red");

                return gradient;
              },
              tension: 0.1,
              pointRadius: 10,
              pointHoverRadius: 12,
              pointStyle: emojiImages,
              backgroundColor: "blue",
            },
            {
              label: t("note"),
              data: moods,
              fill: false,
              borderColor: "green",
              pointRadius: 0,
              pointHoverRadius: 0,
              backgroundColor: "green",
            },
          ],
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching mood and energy states:", error);
        setErrorMessage(t("load_data_failed"));
        setLoading(false);
      }
    };

    fetchData();
  }, [dataUpdated, selectedDate, viewMode]);

  if (loading) {
    return <p>{t("loading")}</p>;
  }

  if (errorMessage) {
    return <p className="error-message">{errorMessage}</p>;
  }

  // Define the canvasBackgroundColor plugin
  const canvasBackgroundColor = {
    id: 'canvasBackgroundColor',
    beforeDraw: (chart) => {
      const { ctx, chartArea } = chart;
      const gradient = ctx.createLinearGradient(
        0,
        chartArea.top,
        0,
        chartArea.bottom
      );
      gradient.addColorStop(0, "rgba(255, 0, 0, 0.4)");
      gradient.addColorStop(0.5, "rgba(0, 255, 0, 0.4)");
      gradient.addColorStop(1, "rgba(0, 0, 255, 0.4)");

      ctx.save();
      ctx.fillStyle = gradient;
      ctx.fillRect(
        chartArea.left,
        chartArea.top,
        chartArea.right - chartArea.left,
        chartArea.bottom - chartArea.top
      );
      ctx.restore();
    }
  };

  return (
    <div className="mood-energy-chart-container">
      <h2>{t("headline_over_time")}</h2>
      <div className="date-picker-container">
        <label htmlFor="date-picker">{t("choose_date")}</label>
        <DatePicker
          id="date-picker"
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd.MM.yyyy"
          className="date-picker"
        />
      </div>
      <div className="view-mode-buttons">
        <button
          onClick={() => {
            setErrorMessage("");
            setMessage("");
            setViewMode("day");
          }}
          className={viewMode === "day" ? "active" : ""}
        >
          {t("day")}
        </button>
        <button
          onClick={() => {
            setErrorMessage("");
            setMessage("");
            setViewMode("week");
          }}
          className={viewMode === "week" ? "active" : ""}
        >
          {t("week")}
        </button>
        <button
          onClick={() => {
            setErrorMessage("");
            setMessage("");
            setViewMode("month");
          }}
          className={viewMode === "month" ? "active" : ""}
        >
          {t("month")}
        </button>
        <button
          onClick={() => {
            setErrorMessage("");
            setMessage("");
            setViewMode("year");
          }}
          className={viewMode === "year" ? "active" : ""}
        >
          {t("year")}
        </button>
      </div>
      {chartData ? (
        <div className="mood-energy-chart">
          <Line
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: "time",
                  time: {
                    unit:
                      viewMode === "day"
                        ? "hour"
                        : viewMode === "week"
                        ? "day"
                        : viewMode === "month"
                        ? "day"
                        : "month",
                    tooltipFormat: "PPpp",
                    displayFormats: {
                      hour: "HH:mm",
                      day: "dd.MM",
                      month: "MMM yyyy",
                    },
                  },
                  ticks: {
                    source: "auto",
                    autoSkip: true,
                    maxRotation: 45,
                    minRotation: 45,
                    callback: function (value, index, values) {
                      const date = new Date(value);
                      if (viewMode === "day") {
                        return date.toLocaleString(userLocale, {
                          timeZone: userTimeZone,
                          hour: "2-digit",
                          minute: "2-digit",
                        });
                      } else if (viewMode === "week" || viewMode === "month") {
                        return date.toLocaleString(userLocale, {
                          timeZone: userTimeZone,
                          day: "2-digit",
                          month: "2-digit",
                        });
                      } else if (viewMode === "year") {
                        return date.toLocaleString(userLocale, {
                          timeZone: userTimeZone,
                          month: "short",
                          year: "numeric",
                        });
                      }
                      return date.toLocaleString(userLocale, {
                        timeZone: userTimeZone,
                      });
                    },
                  },
                },
                y: {
                  min: 1,
                  max: 9,
                  ticks: {
                    stepSize: 1,
                    callback: function (value) {
                      const isMobile = window.innerWidth <= 768;

                      if (isMobile) {
                        return value;
                      }

                      switch (value) {
                        case 1:
                          return t("energy_level_1") + " - 1";
                        case 2:
                          return t("energy_level_2") + " - 2";
                        case 3:
                          return t("energy_level_3") + " - 3";
                        case 4:
                          return t("energy_level_4") + " - 4";
                        case 5:
                          return t("energy_level_5") + " - 5";
                        case 6:
                          return t("energy_level_6") + " - 6";
                        case 7:
                          return t("energy_level_7") + " - 7";
                        case 8:
                          return t("energy_level_8") + " - 8";
                        case 9:
                          return t("energy_level_9") + " - 9";
                        default:
                          return value;
                      }
                    },
                  },
                },
              },
              plugins: {
                tooltip: {
                  callbacks: {
                    label: (tooltipItem) => {
                      const energy =
                        chartData.datasets[0].data[tooltipItem.dataIndex];
                      const mood =
                        chartData.datasets[1].data[tooltipItem.dataIndex];
                      return `Energy: ${energy}, 🟩 Note: ${mood}`;
                    },
                    title: (tooltipItems) => {
                      const date = tooltipItems[0].label;
                      return new Date(date).toLocaleString(userLocale, {
                        timeZone: userTimeZone,
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                      });
                    },
                  },
                },
                datalabels: {
                  display: false,
                },
              },
            }}
            plugins={[canvasBackgroundColor]}
          />
        </div>
      ) : (
        <p>{t("no_data_to_display")}</p>
      )}
      <button onClick={deleteLastEntry} className="button">
        {t("delete_last_entry_button")}
      </button>
      {message && <p className="message success">{message}</p>}
      {errorMessage && <p className="message error">{errorMessage}</p>}
    </div>
  );
};

export default MoodEnergySummaryChart;
