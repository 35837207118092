import React from 'react';
import './LogoutWarning.css';
import { useTranslation } from 'react-i18next';
import { getProfile } from '../../services/api'; 

const LogoutWarning = ({ onClose }) => {
  const { t } = useTranslation();

  const handleOkClick = async () => {
    try {
      const response = await getProfile();
      console.log('Profile fetched successfully:', response);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
    onClose();
  };

  return (
    <div className="logout-warning">
      <p>{t("logout_warning")}</p>
      <button onClick={handleOkClick}>OK</button>
    </div>
  );
};

export default LogoutWarning;