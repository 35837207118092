// ResetPassword.js
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import api from "../../services/api";
import "./Login.css";
import "../Message.css";

const ResetPassword = () => {
  const { t } = useTranslation();
  const { token } = useParams();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(null);
  const [isValidToken, setIsValidToken] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    // Tarkista tokenin voimassaolo
    const checkTokenValidity = async () => {
      try {
        await api.post(`/verify_reset_token`, { token });
        setIsValidToken(true);
      } catch (error) {
        setError(t("invalid_or_expired_token"));
        setIsValidToken(false);
      }
    };

    checkTokenValidity();
  }, [token, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError(t("passwords_do_not_match"));
      return;
    }

    try {
      const response = await api.post(`/reset_password/${token}`, {
        new_password: newPassword,
      });
      setSuccess(t("password_reset_successfully"));
      setError(null);
      setTimeout(() => {
        history.push("/login");
      }, 5000);
    } catch (error) {
      setError(error.response.data.error);
      console.error("Error resetting password:", error);
      setSuccess(null);
    }
  };

  if (!isValidToken) {
    return <div className="message error">{error}</div>;
  }

  return (
    <div className="login-container">
      <h2>{t("change_password")}</h2>
      {error && <div className="message error">{error}</div>}
      {success && <div className="message success">{success}</div>}
      <form onSubmit={handleSubmit} className="login-form">
      <div className="form-group">
      <label> {t("new_password")}</label>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            id="newPassword"
            className="form-control"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            tabIndex="1"
          />
          <button
            type="button"
            className="toggle-password-btn"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? t("hide") : t("show")}
          </button>
        </div>
        <label>{t("confirm_new_password")}</label>
        <div className="password-container">
          <input
            type={showPassword ? "text" : "password"}
            id="confirmPassword"
            className="form-control"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            tabIndex="2"
          />
          <button
            type="button"
            className="toggle-password-btn"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? t("hide") : t("show")}
          </button>
        </div>
        </div>
        <button type="submit" className="button">
          {t("change_password_button")}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;