import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PageHome from "../PageHome";
import PageFeatures from "../Features/PageFeatures";
import Login from "../Auth/Login";
import Register from "../Auth/Register";
import Profile from "../Profile/Profile";
import NotFound from "../NotFound";
import CreateCourse from "../Courses/CreateCourse";
import ValueMapping from "../Features/ValueMapping/ValueMapping";
import ImbalanceMapping from "../Features/ImbalanceMapping/ImbalanceMapping";
import JournalList from "../Journal/JournalList";
import JournalForm from "../Journal/JournalForm";
import JournalEdit from "../Journal/JournalEdit";
import Settings from "../Settings/Settings";
import MoodEnergyTracking from "../Features/MoodEnergy/MoodEnergyTracking";
import CoursesList from "../Courses/CoursesList";
import CourseDetail from "../Courses/CourseDetail";
import Lesson from "../Lessons/Lesson";
import CourseForm from "../Courses/CourseForm";
import CourseEdit from "../Courses/CourseEdit";
import LessonForm from "../Lessons/LessonForm";
import LessonEdit from "../Lessons/LessonEdit";
import RoleBasedRoute from '../Auth/RoleBasedRoute';
import ResetPassword from "../Auth/ResetPassword";
import ForgotPassword from "../Auth/ForgotPassword";
import JudgeYourNeighbor from "../Features/JudgeYourNeighbor/JudgementForm";

const Routes = ({ loggedIn, setLoggedIn }) => (
  <Switch>
    <Route exact path="/">
      <PageHome />
    </Route>

    <RoleBasedRoute
      exact
      path="/courses/:courseId/lessons/create"
      component={LessonForm}
      isAuthenticated={loggedIn}
      requiredRole="admin"
    />
    <RoleBasedRoute
      path="/courses/:courseId/lessons/edit/:lessonId"
      component={LessonEdit}
      isAuthenticated={loggedIn}
      requiredRole="admin"
    />
    <RoleBasedRoute
      exact
      path="/courses/:courseId/lessons/:lessonId"
      component={Lesson}
      isAuthenticated={loggedIn}
      requiredRole="admin"
    />
    <RoleBasedRoute
      exact
      path="/courses/create"
      component={CourseForm}
      isAuthenticated={loggedIn}
      requiredRole="admin"
    />
    <RoleBasedRoute
      path="/courses/edit/:courseId"
      component={CourseEdit}
      isAuthenticated={loggedIn}
      requiredRole="admin"
    />
    <RoleBasedRoute
      exact
      path="/courses/:courseId"
      component={CourseDetail}
      isAuthenticated={loggedIn}
      requiredRole="admin"
    />
    <RoleBasedRoute
      exact
      path="/courses"
      component={CoursesList}
      isAuthenticated={loggedIn}
      requiredRole="admin"
    />

    <PrivateRoute
      path="/features/values"
      component={ValueMapping}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/features/imbalance"
      component={ImbalanceMapping}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/features/moodEnergy"
      component={MoodEnergyTracking}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/features/judgeYourNeighbor"
      component={JudgeYourNeighbor}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/features"
      component={PageFeatures}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/journal/create"
      component={JournalForm}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/journal/edit/:id"
      component={JournalEdit}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/journal"
      component={JournalList}
      isAuthenticated={loggedIn}
    />
    <Route path="/login">
      <Login
        onLogin={() => {
          setLoggedIn(true);
        }}
      />
    </Route>
    <Route path="/register">
      <Register />
    </Route>
    <Route path="/forgot_password">
      <ForgotPassword />
    </Route>
    <Route path="/reset_password/:token">
      <ResetPassword />
    </Route>
    <PrivateRoute
      path="/profile"
      component={Profile}
      isAuthenticated={loggedIn}
    />
    <PrivateRoute
      path="/settings"
      component={Settings}
      isAuthenticated={loggedIn}
    />
    <Route path="*">
      <NotFound />
    </Route>
  </Switch>
);

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export default Routes;