import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomEditor from "../CustomEditor"; // Import the CustomEditor
import "./Journal.css";
import api from "../../services/api";

const JournalEdit = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [entry, setEntry] = useState({
    title: "",
    content: "",
    category: "",
    tags: [],
  });
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    fetchEntry();
  }, []);

  const fetchEntry = async () => {
    try {
      const response = await api.get(`/journal/${id}`);
      setEntry(response.data);
    } catch (error) {
      console.error("Error fetching journal entry", error);
      setError(t("fetch_error"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.put(
        `/journal/${id}`,
        {
          title: entry.title,
          content: entry.content,
          category: entry.category,
          tags: entry.tags,
        });
      history.push("/journal");
    } catch (error) {
      console.error("Error updating journal entry", error);
      setError(error.response.data.error);
    }
  };

  const handleTagsChange = (e) => {
    setEntry({
      ...entry,
      tags: e.target.value.split(",").map((tag) => tag.trim()),
    });
  };

  return (
    <div className="journal-container">
      <h2 className="journal-header">{t("edit_journal_entry")}</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">{t("title")}</label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={entry.title}
            onChange={(e) => setEntry({ ...entry, title: e.target.value })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">{t("content")}</label>

          <div className="card journal-editor">
            <CustomEditor
              value={entry.content}
              onTextChange={(e) => setEntry({ ...entry, content: e.htmlValue })}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="category">{t("category")}</label>
          <select
            id="category"
            className="form-control"
            value={entry.category}
            onChange={(e) => setEntry({ ...entry, category: e.target.value })}
          >
            <option value="">{t("select_category")}</option>
            <option value="IRL">{t("irl_events")}</option>
            <option value="dreams">{t("dreams")}</option>
            <option value="trance">{t("trance_state")}</option>
            <option value="exercises">{t("exercises")}</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tags">{t("tags")}</label>
          <input
            type="text"
            id="tags"
            className="form-control"
            placeholder={t("tags_placeholder")}
            value={entry.tags.join(", ")}
            onChange={handleTagsChange}
          />
        </div>
        <button type="submit" className="btn-create-entry">
          {t("save")}
        </button>
      </form>
    </div>
  );
};

export default JournalEdit;
