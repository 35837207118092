import React, { useState } from "react";
import TrackMoodEnergy from "./MoodEnergyService";
import "./MoodEnergyTracking.css";
import MoodEnergySummaryChart from "./MoodEnergySummaryChart";
import MoodEnergyPieChart from "./MoodEnergyPieChart";
import CircumplexChart from "./CircumplexChart";
import CircumplexChartPros from "./CircumplexChartPros";

const MoodEnergyTracking = () => {
  const [dataUpdated, setDataUpdated] = useState(false);

  // Function to handle data update
  const onDataUpdate = () => {
    setDataUpdated(!dataUpdated); // Toggle to force re-render
  };

  return (
    <div>
      <TrackMoodEnergy onDataUpdate={onDataUpdate} />
      {/* Pass dataUpdated to force the chart to fetch new data */}
      <MoodEnergySummaryChart dataUpdated={dataUpdated} onDataUpdate={onDataUpdate}/>
      {/* <MoodEnergyPieChart /> */}
      <CircumplexChart dataUpdated={dataUpdated}/>
      <CircumplexChartPros dataUpdated={dataUpdated}/>
    </div>
  );
};

export default MoodEnergyTracking;
