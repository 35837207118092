import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom"; // Use useHistory and Link for navigation
import "./Journal.css"; // Import the CSS file for styling
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import JournalEntries from "./JournalEntries"; // Import the JournalEntries component
import "quill/dist/quill.snow.css"; // Ensure Quill styles are imported
import InfiniteScroll from "react-infinite-scroll-component";
import SearchBar from "./SearchBar";

const JournalList = () => {
  const [entries, setEntries] = useState([]);
  const [category, setCategory] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [sortOrder, setSortOrder] = useState("mostRecent"); // New state for sorting
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const history = useHistory(); // Initialize useHistory for navigation
  const { t } = useTranslation();
  const limit = 10; // Number of entries per page

  const handleSearch = async (query) => {
    setSearchQuery(query); // Save the current query for highlighting
    if (!query.trim()) {
      // If query is empty, reset to default view (all entries)
      setPage(1);
      setHasMore(true);
      fetchEntries(1);
      return;
    }
    try {
      const response = await api.get("/journal/search", {
        params: { query },
      });
      setEntries(response.data);
      // Disable infinite scrolling when search results are displayed
      setHasMore(false);
    } catch (error) {
      console.error("Search error:", error);
    }
  };

  useEffect(() => {
    fetchEntries(1);
  }, [category, sortOrder]); // Trigger fetch on category or sortOrder change

  useEffect(() => {
    setPage(1); // Reset page to 1
    setHasMore(true); // Re-enable infinite scrolling
    fetchEntries(1); // Fetch the first page for the new filter values
  }, [category, sortOrder]);

  const loadMore = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchEntries(nextPage);
  };

  const fetchEntries = async (pageNumber = 1) => {
    try {
      const offset = (pageNumber - 1) * limit;
      const response = await api.get(`/journal`, {
        params: {
          category: category || undefined, // Only include category if it's set
          sortOrder: sortOrder || "mostRecent", // Include sortOrder
          limit,
          offset,
        },
      });
      const newEntries = response.data;

      if (pageNumber === 1) {
        setEntries(newEntries);
      } else {
        setEntries((prevEntries) => [...prevEntries, ...newEntries]);
      }

      // If fewer entries than 'limit' were returned, there's no more data.
      setHasMore(newEntries.length === limit);
    } catch (error) {
      console.error("Error fetching journal entries", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const confirmDelete = window.confirm(t("confirm_delete_journal"));
      if (!confirmDelete) {
        return;
      }
      await api.delete(`/journal/${id}`);
      fetchEntries(); // Refresh the list after deletion
    } catch (error) {
      console.error("Error deleting journal entry", error);
    }
  };

  return (
    <div className="journal-container">
      <div className="journal-header">
        <h2>{t("journal_header")}</h2>
      </div>
      <p>{t("journal_message")}</p>

      {/* Add a button to create a new journal entry */}
      <Link to="/journal/create" className="btn-create-entry">
        {t("new_entry")}
      </Link>

      {/* Pass the handleSearch function as onSearch prop */}
      <div className="journal-search">
        <SearchBar onSearch={handleSearch} />
      </div>

      {/* Add filters for category and sort order */}
      <div className="filters">
        {/* Category Filter */}
        <div className="filter-group category-filter">
          <label htmlFor="category" className="category-label">
            {t("filter_category")}
          </label>
          <select
            id="category"
            className="category-dropdown"
            onChange={(e) => setCategory(e.target.value)}
            value={category}
          >
            <option value="">{t("filter_all")}</option>
            <option value="IRL">{t("filter_IRL")}</option>
            <option value="dreams">{t("filter_dreams")}</option>
            <option value="trance">{t("filter_trances")}</option>
            <option value="exercises">{t("filter_exercises")}</option>
          </select>
        </div>

        {/* Sort Filter */}
        <div className="filter-group sort-filter">
          <label htmlFor="sortOrder" className="sort-label">
            {t("sort_by")}
          </label>
          <select
            id="sortOrder"
            className="sort-dropdown"
            onChange={(e) => setSortOrder(e.target.value)}
            value={sortOrder}
          >
            <option value="mostRecent">{t("sort_most_recent")}</option>
            <option value="oldest">{t("sort_oldest")}</option>
          </select>
        </div>
      </div>

      {/* Wrap journal entries with InfiniteScroll */}
      <InfiniteScroll
        dataLength={entries.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
        endMessage={<p>{t("no_more_entries")}</p>}
      >
        <div className="ql-snow">
          <div className="ql-editor">
            <JournalEntries
              entries={entries}
              searchQuery={searchQuery} // Pass the search query down to highlight matches
              handleDelete={handleDelete}
              history={history}
            />
          </div>
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default JournalList;
