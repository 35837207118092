import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

// SearchBar component for filtering journal entries
// Receives a callback function onSearch to handle search
// Renders an input field and a search button
// Triggers onSearch callback with the search query
// on button click or Enter key press
// Empty query triggers default view (all entries)
// but is handled by the parent component (JournalList)
const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");
  const { t } = useTranslation();

  const handleSearchClick = () => {
    onSearch(query);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchClick();
    }
  };

  return (
    <div className="p-inputgroup">
      <InputText
        placeholder={t("search_placeholder")}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown} // Trigger search on Enter key
      />
      <Button
        icon="pi pi-search"
        label={t("search_button")}
        onClick={handleSearchClick}
      />
    </div>
  );
};

export default SearchBar;
