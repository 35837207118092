import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';
import './JudgementForm.css';
import api from '../../../services/api'; // Import the API service

export default function JudgementForm() {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
    emotion: '',
    person: '',
    action: '',
    advice: '',
    needs: '',
    complaints: '',
    experience: '',
    question1: '',
    question2: '',
    question3: '',
    question4: '',
    turnaround: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(formData);
  };

  const handleSaveToJournal = async () => {
    const journalEntry = {
      title: t('judge_title'),
      content: `
        ${t('judge_questions', { returnObjects: true }).map((question, index) => `
          <h4>${question}</h4>
          <p>${formData[Object.keys(formData)[index]]}</p>
          <br>
        `).join('').replace(/\n\s*/g, '')}
        <h4>${t('judge_four_questions_title')}</h4>
        <br>
        <p>${t('judge_four_questions')}</p>
        ${t('judge_four_questions_labels', { returnObjects: true }).map((label, index) => `
          <br>
          <h4>${label}</h4>
          <p>${formData[`question${index + 1}`]}</p>
        `).join('').replace(/\n\s*/g, '')}
        <br>
        <p>${t('judge_turnaround_text')}</p>
        <br>
        <h4>${t('judge_turnaround_label')}</h4>
        <p>${formData.turnaround}</p>
      `.replace(/\n\s*/g, ''),
      category: 'exercises',
      tags: [t('tags_judgement'), t('tags_exercise')]
    };
  
    try {
      await api.post('/journal', journalEntry);
      alert(t('journal_entry_saved'));
      setFormData({
        emotion: '',
        person: '',
        action: '',
        advice: '',
        needs: '',
        complaints: '',
        experience: '',
        question1: '',
        question2: '',
        question3: '',
        question4: '',
        turnaround: '',
      });
      /* window.location.reload(); */
    } catch (error) {
      console.error('Error saving journal entry', error);
      alert(t('journal_entry_save_error'));
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (Object.values(formData).some(value => value !== '')) {
        const message = t('unsaved_changes_warning');
        e.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [formData, t]);

  const isFormDirty = Object.values(formData).some(value => value !== '');

/*   const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }; */

  return (
    <Card className="container">
              <Prompt
          when={isFormDirty}
          message={t('unsaved_changes_warning')}
        />
{/*         <div className="language-switcher">
          <button onClick={() => changeLanguage('fi')}>FI</button>
          <button onClick={() => changeLanguage('en')}>EN</button>
        </div> */}
        <h1 className="title-h1">{t('judge_title')}</h1>
        <p className="subtitle">
          {t('judge_subtitle')} <a href="https://thework.com" target="_blank" className="link">{t('judge_link')}</a>.
        </p>
        <p className="text">
          {t('judge_text')}
        </p>
        <form onSubmit={handleSubmit} className="form">
          {t('judge_questions', { returnObjects: true }).map((question, index) => (
            <div key={index}>
              <label className="label">{question}</label>
              <InputTextarea
                /* type="text" */
                name={Object.keys(formData)[index]}
                /* placeholder={t('judge_placeholders', { returnObjects: true })[index]} */
                placeholder={t('judge_examples', { returnObjects: true })[index]}
                value={formData[Object.keys(formData)[index]]}
                onChange={handleChange}
                /* className="input" */
                className='textarea'
                rows="4"
              />
              {/* <p className="text-example">{t('judge_examples', { returnObjects: true })[index]}</p> */}
            </div>
          ))}

          <h2 className="title-h2">{t('judge_four_questions_title')}</h2>
          <p className="text">
            {t('judge_four_questions')}
          </p>
          {t('judge_four_questions_labels', { returnObjects: true }).map((label, index) => (
            <div key={index}>
              <label className="label">{label}</label>
              <InputTextarea
                name={`question${index + 1}`}
                placeholder={t('judge_four_questions_placeholders', { returnObjects: true })[index]}
                value={formData[`question${index + 1}`]}
                onChange={handleChange}
                className="textarea"
                rows="4"
              />
            </div>
          ))}

          <h2 className="title-h2">{t('judge_turnaround_title')}</h2>
          <ul className="text">
            {t('judge_turnaround_examples', { returnObjects: true }).map((example, index) => (
              <li key={index}>{example}</li>
            ))}
          </ul>
          <p className="text">
            {t('judge_turnaround_text')}
          </p>
          <div>
            <label className="label">{t('judge_turnaround_label')}</label>
            <InputTextarea
              name="turnaround"
              /* placeholder="Pohdinnat..." */
              value={formData.turnaround}
              onChange={handleChange}
              className="textarea"
              rows="4"
            />
          </div>
          
          <Button 
            label={t('judge_submit')}
            className="p-button"
            onClick={handleSaveToJournal}
          />
        </form>
    </Card>
  );
}