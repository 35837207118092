import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomEditor from "../CustomEditor";
import api from "../../services/api";
import "./Course.css";

const CourseForm = () => {
  const { t } = useTranslation();
  const [course, setCourse] = useState({
    title: "",
    description: "",
    language: "en",
  });
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!course.title || !course.description) {
      setError(t("course_info_missing"));
      return;
    }

    try {
      await api.post(`/courses`, course);

      history.push("/courses");
    } catch (error) {
      console.error("Error updating course:", error);
      setError(t("update_error"));
    }
  };

  return (
    <div className="create-course-container">
      <h2>{t("create_course_header")}</h2>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">{t("course_title")}</label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={course.title}
            onChange={(e) => setCourse({ ...course, title: e.target.value })}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">{t("course_description")}</label>
          <CustomEditor
            value={course.description}
            onTextChange={(e) =>
              setCourse({ ...course, description: e.htmlValue })
            }
            className="custom-editor"
          />
        </div>
        <div className="form-group">
          <label htmlFor="language">{t("course_language")}</label>
          <select
            id="language"
            className="form-control"
            value={course.language}
            onChange={(e) => setCourse({ ...course, language: e.target.value })}
          >
            <option value="en">{t("language_english")}</option>
            <option value="fi">{t("language_finnish")}</option>
          </select>
        </div>
        <button type="submit" className="btn-submit">
          {t("create_course_button")}
        </button>
      </form>
    </div>
  );
};

export default CourseForm;
