import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";
import "./MoodEnergyTracking.css";
import { set } from "date-fns";
import api from "../../../services/api";

const TrackMoodEnergy = ({ onDataUpdate }) => {
  const { t } = useTranslation();
  const [mood, setMood] = useState("");
  const [energy, setEnergy] = useState(5); // Energy slider starts at 5
  const [emoji, setEmoji] = useState("😐"); // Default emoji
  const [valence, setValence] = useState(0);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showDateTimeFields, setShowDateTimeFields] = useState(false);
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  // Function to handle mood and energy submission
  const handleSubmit = async (e) => {
    setErrorMessage("");
    setMessage("");
    e.preventDefault();

    if (
      (showDateTimeFields && date && !time) ||
      (!showDateTimeFields && date && !time)
    ) {
      setErrorMessage(t("time_required"));
      return;
    }
    if (
      (showDateTimeFields && !date && time) ||
      (!showDateTimeFields && !date && time)
    ) {
      setErrorMessage(t("date_required"));
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const data = {
      mood,
      energy,
      emoji,
      valence,
      date: showDateTimeFields ? date : undefined,
      time: showDateTimeFields ? time : undefined,
      timezone: showDateTimeFields ? timezone : undefined,
    };

    try {
      const response = await api.post("/mood-energy/track-state", data);
      if (response.status === 201 || response.status === 200) {
        setMessage(t("mood_energy_recorded"));
        if (onDataUpdate) onDataUpdate(); // Kutsu onDataUpdate-funktiota
        setMood("");
        setEnergy(5);
        setEmoji("😐");
        setValence(0);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      } else {
        setErrorMessage(error.response.data.error);
      }
    } catch (error) {
      setErrorMessage(error.response.data.error);
    }
  };

  // Function to get text based on energy value
  const getEnergyText = (value) => {
    switch (value) {
      case 1:
        return t("energy_level_1");
      case 2:
        return t("energy_level_2");
      case 3:
        return t("energy_level_3");
      case 4:
        return t("energy_level_4");
      case 5:
        return t("energy_level_5");
      case 6:
        return t("energy_level_6");
      case 7:
        return t("energy_level_7");
      case 8:
        return t("energy_level_8");
      case 9:
        return t("energy_level_9");
      default:
        return "";
    }
  };

  const getValenceText = (value) => {
    switch (value) {
      case 1:
        return t("valence_level_1");
      case 2:
        return t("valence_level_2");
      case 3:
        return t("valence_level_3");
      case 4:
        return t("valence_level_4");
      default:
        return "";
    }
  };

  // Get today's date in YYYY-MM-DD format
  const today = new Date().toISOString().split("T")[0];

  return (
    <div className="track-mood-energy-container">
      <h2>{t("track_mood_energy")}</h2>
      <form onSubmit={handleSubmit}>
        <label>{t("note")}:</label>
        <input
          type="text"
          value={mood}
          onChange={(e) => setMood(e.target.value)}
          placeholder={t("note_placeholder")}
        />

        <label>{t("mood")}:</label>
        <select
          value={emoji}
          onChange={(e) => {
            setEmoji(e.target.value);
            if (e.target.value === "😐") {
              setValence(0);
            } else {
              setValence(1); // Default valence for non-neutral emojis
            }
          }}
        >
          <option value="😊">😊 {t("happy")}</option>
          <option value="😢">😢 {t("sad")}</option>
          <option value="😡">😡 {t("angry")}</option>
          <option value="😨">😨 {t("fearful")}</option>
          <option value="😐">😐 {t("neutral")}</option>
          <option value="🥳">🥳 {t("party")}</option>
          <option value="😂">😂 {t("laughing")}</option>
          {/* <option value="🤔">🤔 {t("thinking")}</option> */}
          <option value="🤢">🤢 {t("disgusted")}</option>
          <option value="😰">😰 {t("anxious")}</option>
          <option value="😟">😟 {t("worried")}</option>
          {/* <option value="😴">😴 {t("tired")}</option> */}
          <option value="😇">😇 {t("blessed")}</option>
          <option value="🥵">🥵 {t("exhausted")}</option>
          <option value="😞">😞 {t("depressed")}</option>
          <option value="😳">😳 {t("ashamed")}</option>
          <option value="😬">😬 {t("nervous")}</option>
          <option value="🥴">🥴 {t("brainfog")}</option>
          <option value="😌">😌 {t("relieved")}</option>
          <option value="🤩">🤩 {t("excited")}</option>
        </select>
        <br />
        {emoji !== "😐" && (
          <>
            <label>{t("valence_level")}:</label>
            <div className="slider-container">
              <input
                type="range"
                min="1"
                max="4"
                value={valence}
                className="valence-slider"
                onChange={(e) => setValence(parseInt(e.target.value))}
              />
              <span>
                {valence} - {getValenceText(valence)}
              </span>
            </div>
            <br />
          </>
        )}
        <label>{t("energy_level")}:</label>
        <div className="slider-container">
          <input
            type="range"
            min="1"
            max="9"
            value={energy}
            className="slider"
            onChange={(e) => setEnergy(parseInt(e.target.value))}
          />
          <span>
            {energy} - {getEnergyText(energy)}
          </span>
        </div>
        <br />
        <p className="forgotten-text">{t("add_forgotten_mood_energy")}</p>
        <button
          type="button"
          className="forgot-button"
          onClick={() => setShowDateTimeFields(!showDateTimeFields)}
        >
          {showDateTimeFields ? t("hide_date_time") : t("add_date_time")}
        </button>
        {showDateTimeFields && (
          <div className="forgotten-container">
            <div>
              <label>{t("date")}</label>
              <input
                type="date"
                value={date}
                max={today}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div>
              <label>{t("time")}</label>
              <input
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
              />
            </div>
          </div>
        )}
        <br />
        <button type="submit" className="button">
          {t("submit")}
        </button>
      </form>

      {message && <p className="message success">{message}</p>}
      {errorMessage && <p className="message error">{errorMessage}</p>}
    </div>
  );
};

export default TrackMoodEnergy;