// src/components/Lesson.js
import React, { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../Courses/Course.css";
import api from "../../services/api";

const Lesson = () => {
  const { courseId, lessonId } = useParams(); // Get courseId and lessonId from route parameters
  const [lesson, setLesson] = useState(null);
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const history = useHistory();

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const response = await api.get(
          `/courses/${courseId}/lessons/${lessonId}`
        );
        console.log("Fetched HTML content:", response.data.content); // Debug fetched HTML
        setLesson(response.data);
      } catch (err) {
        console.error("Error fetching lesson:", err);
        setError("Failed to load lesson. Please try again.");
      }
    };

    fetchLesson();
  }, [courseId, lessonId]);

  const handleCompleteLesson = async () => {
    try {
      console.log(
        `Completing lesson at URL: ${api.defaults.baseURL}/courses/${courseId}/lessons/${lessonId}/complete`
      );

      await api.post(`/courses/${courseId}/lessons/${lessonId}/complete`);

      setLesson((prevLesson) => ({ ...prevLesson, completed: true }));
      setError(""); // Clear any previous error if completion succeeds
      console.log("Lesson marked as completed.");
    } catch (err) {
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data.message === "Lesson already completed"
      ) {
        setError("This lesson is already marked as completed.");
      } else {
        setError("Failed to mark lesson as completed.");
      }
      console.error("Error completing lesson:", err);
    }
  };

  const handleDelete = async () => {
    if (window.confirm(t("delete_confirmation"))) {
      try {
        console.log(
          `Deleting lesson at URL: ${api.defaults.baseURL}/courses/${courseId}/lessons/${lessonId}`
        );

        await api.delete(`/courses/${courseId}/lessons/${lessonId}`);

        alert(t("lesson_deleted_success"));
        history.push(`/courses/${courseId}`); // Redirect to course details
      } catch (err) {
        console.error("Error deleting lesson:", err);
        setError("Failed to delete the lesson.");
      }
    }
  };

  if (error) return <p className="error-message">{error}</p>;
  if (!lesson) return <p>Loading lesson details...</p>;

  return (
    <div className="lesson-container">
      {lesson.image_above && (
        <img
          src={lesson.image_above}
          alt={lesson.title}
          className="lesson-image-above"
        />
      )}

      <div className="ql-snow ql-editor">
        <h1>{lesson.title}</h1>
        {/* Assuming `content` contains the HTML string from the database */}
        <div dangerouslySetInnerHTML={{ __html: lesson.content }} />
      </div>

      <div className="lesson-below-part">
        {lesson.image_below && (
          <img
            src={lesson.image_below}
            alt={lesson.title}
            className="lesson-image-below"
          />
        )}

        {lesson.completed ? (
          <div className="button-center">
            <div className="lesson-completed-message">
              <span className="lesson-completed-icon">✔</span>
              <span>{t("lesson_completed")}</span>
            </div>
          </div>
        ) : (
          <div className="button-center">
            <button className="cool-button" onClick={handleCompleteLesson}>
              {t("mark_completed")}
            </button>
          </div>
        )}

        <div className="course-actions">
          <Link
            to={`/courses/${courseId}/lessons/edit/${lessonId}`}
            className="btn-edit-course"
          >
            {t("edit_lesson_button")}
          </Link>
          <button onClick={handleDelete} className="btn-delete-course">
            {t("delete_lesson_button")}
          </button>
        </div>
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Lesson;
