// ForgotPassword.js
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import { useHistory } from "react-router-dom";
import "./Login.css";
import "../Message.css";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
        setError(t("email_is_required"));
        return;
      }
  
      if (!email.includes("@")) {
        setError(t("invalid_email_format"));
        return;
      }

    try {
      const response = await api.post("/reset_password_request", { email });
      setSuccess(t("reset_link_sent"));
      setError(null);
      setTimeout(() => {
        history.push("/login");
      }, 5000); // Redirect to login page after 3 seconds
    } catch (error) {
      setError(error.response.data.error);
      setSuccess(null);
    }
  };

  return (
    <div className="login-container">
      <h2>{t("forgot_password")}</h2>
      {error && <div className="message error">{error}</div>}
      {success && <div className="message success">{success}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">{t("email")}</label>
          <input
            type="email"
            id="email"
            className="form-control"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button type="submit" className="button">
          {t("send_reset_link")}
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;