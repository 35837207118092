import axios from "axios";
import i18n from "../i18n";
import { addEventListeners, removeEventListeners, resetLogoutTimer, handleLogout, addVisibilityChangeListener, removeVisibilityChangeListener } from "./sessionManager";

const API_URL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = true;


// Get CSRF token from cookie
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

// Create an Axios instance
const api = axios.create({
  baseURL: API_URL,
  headers: {
      'Content-Type': 'application/json',
      'Accept-Language': localStorage.getItem('language') || i18n.language, // Add the selected language to the headers
  },
  withCredentials: true,
});

// Add a request interceptor to include the CSRF token and JWT token in the headers
api.interceptors.request.use(config => {
  const csrfToken = getCookie('sj_ps_cookie');
  //console.log("CSRF token:", csrfToken);
  if (csrfToken) {
    config.headers['sjx'] = csrfToken;
  }
  // Reset the logout timer on each request
  if (localStorage.getItem("isLoggedIn") === "true") {
    resetLogoutTimer();
  };
  return config;
}, error => {
  return Promise.reject(error);
});


// Add a request interceptor to update the Accept-Language header dynamically
api.interceptors.request.use((config) => {
  config.headers['Accept-Language'] = localStorage.getItem('language') || i18n.language;
  return config;
});

// Register API call
export const register = async (username, password, email, recaptchaToken) => {
  try {
    const response = await api.post('/register', {
      username,
      password,
      email,
      recaptchaToken,
    });
    return response.data;
  } catch (error) {
    console.error("Error registering:", error);
    return (
      error.response?.data || {
        message: "An error occurred during registration",
      }
    );
  }
};

// Login API call
export const login = async (identifier, password) => {
  try {
    const response = await api.post('/login', {
      identifier,
      password,
    });

    addEventListeners(); // Add event listeners for user activity
    addVisibilityChangeListener();

    return response.data;
  } catch (error) {
    console.error("Error logging in:", error);
    return error.response?.data || { message: "Invalid username/email or password" };
  }
};

// Logout API call
export const logout = async () => {
  try {
    const response = await api.post('/logout');
    //console.log("Logout response:", response); // Debugging log

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("superApp_sTime");
    removeEventListeners();
    removeVisibilityChangeListener();
    //console.log("isLoggedIn", isLoggedIn());
    return response.data;
  } catch (error) {
    console.error("Logout error:", error.response ? error.response.data : error.message);
    throw error;
  }
};


// Fetch profile data (user's username)
export const getProfile = async () => {
  try {
    const response = await api.get('/profile');

    //console.log("Profile data:", response.data); // Debugging: Log the profile response

    return response.data; // Return the profile data
  } catch (error) {
    console.error("Error fetching profile:", error);
    throw error;
  }
};

// Run feature API call
export const runFeature = async (inputData) => {
  try {
    const response = await api.post(
      '/run_feature',
      {
        input_data: inputData,
      });
    return response.data;
  } catch (error) {
    console.error("Error running feature:", error);
    return (
      error.response?.data || {
        message: "An error occurred while running the feature",
      }
    );
  }
};

export default api;