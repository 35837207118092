import React, { useState, useEffect } from "react";
import { Menubar } from 'primereact/menubar';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.png";
import RoleBasedComponent from "../Auth/RoleBasedComponent";
//import "primereact/resources/themes/lara-light-indigo/theme.css";
//import "primereact/resources/themes/md-dark-deeppurple/theme.css";
//import "primereact/resources/themes/viva-dark/theme.css";
import "primereact/resources/primereact.min.css";  // PrimeReactin perus-styling
import "primeicons/primeicons.css";  // PrimeIcons, jos käytät kuvakkeita
import "./Menu.css";

function Menu({ loggedIn, handleLogout, switchLanguage }) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 960);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 960);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // PrimeReact menu items
  const items = [
    {
      label: t("menu_home"),
      icon: 'pi pi-fw pi-home',
      command: () => { history.push("/") }
    },
    loggedIn && {
      label: t("menu_course"),
      icon: 'pi pi-fw pi-bookmark',
      command: () => { history.push("/courses") },
      template: (item, options) => (
        <RoleBasedComponent requiredRole="admin" loggedIn={loggedIn}>
          <a className={options.className} onClick={options.onClick}>
            <span className={options.iconClassName}></span>
            <span className={options.labelClassName}>{item.label}</span>
          </a>
        </RoleBasedComponent>
      )
    },
    loggedIn && {
      label: t("menu_features"),
      icon: 'pi pi-fw pi pi-sparkles',
      items: [
        {
          label: t("submenu_features"),
          //icon: 'pi pi-fw pi-list',
          command: () => { history.push("/features") }
        },
        {
          label: t("menu_values"),
          //icon: 'pi pi-fw pi-heart',
          command: () => { history.push("/features/values") }
        },
        {
          label: t("menu_imbalance"),
          //icon: 'pi pi-fw pi-bolt',
          command: () => { history.push("/features/imbalance") }
        },
        {
          label: t("menu_moodEnergy"),
          //icon: 'pi pi-fw pi-chart-bar',
          command: () => { history.push("/features/moodEnergy") }
        },
        {
          label: t("menu_judgeYourNeighbor"),
          command: () => { history.push("/features/judgeYourNeighbor") }
        }
      ]
    },
    loggedIn && {
      label: t("menu_journal"),
      icon: 'pi pi-fw pi-book',
      command: () => { history.push("/journal") }
    },
    loggedIn && {
      label: t("menu_my_account"),
      icon: 'pi pi-fw pi-user',
      items: [
        {
          label: t("menu_profile"),
          icon: 'pi pi-fw pi-user',
          command: () => { history.push("/profile") }
        },
        {
          label: t("menu_settings"),
          icon: 'pi pi-fw pi-cog',
          command: () => { history.push("/settings") }
        },
      ]
    },
    !loggedIn ? {
      label: t("menu_login"),
      icon: 'pi pi-fw pi-sign-in',
      command: () => { history.push("/login") }
    } : {
      label: t("menu_logout"),
      icon: 'pi pi-fw pi-sign-out',
      command: handleLogout
    },
    !loggedIn && {
        label: t("menu_register"),
        icon: 'pi pi-fw pi-user-plus',
        command: () => { history.push("/register") }
      }
  ].filter(Boolean);

  //const start = <img alt="logo" src={logo} style={{ height: '40px' }} />;

  //Add logo and menu items to the start of the menu
  const start = (
    <div className="menu-start" style={{ display: 'flex', alignItems: 'center' }}>
      <img alt="logo" src={logo} style={{ height: '40px', cursor: 'pointer' }}  onClick={() => history.push("/")} />
      {isMobile && loggedIn && (
        <>
          <a className="p-menuitem-link" onClick={() => history.push("/journal")}>
            <span className="pi pi-fw pi-book"></span>
            {/* <span>{t("menu_journal")}</span> */}
          </a>
          <a className="p-menuitem-link" onClick={() => history.push("/features/moodEnergy")}>
            <span className="pi pi-fw pi-chart-bar"></span>
            {/* <span>{t("menu_moodEnergy")}</span> */}
          </a>
        </>
      )}
    </div>
  );
  const end = !loggedIn ? (
    <div className="p-menubar-end">
      <div className="language-switcher">
        <span className="fi fi-gb" onClick={() => switchLanguage("en")} style={{ cursor: "pointer", marginRight: "10px", marginLeft: "10px" }}></span>
        <span className="fi fi-fi" onClick={() => switchLanguage("fi")} style={{ cursor: "pointer" }}></span>
      </div>
    </div>
  ) : null;

  //return <Menubar model={items} start={start} end={end} className="custom-menubar" />;
  return <Menubar model={items} start={start} end={end} />;
}

export default Menu;