import React, { useEffect, useState } from "react";
import { getProfile } from "../../services/api";
import { useTranslation } from "react-i18next";
import Octagon from "./Octagon";
import "./Profile.css";

function Profile() {
  const { t } = useTranslation();
  const [username, setUsername] = useState("");
  const [profileData, setProfileData] = useState(null);
  const [error, setError] = useState("");
  const [personaImbalanceValues, setPersonaImbalanceValues] = useState([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await getProfile();
        
        if (response && response.username) {
          setUsername(response.username);
          setProfileData(response);

          if (response.persona_imbalance) {
            const parsedValues = response.persona_imbalance
              .split(",")
              .map((value) => value.trim() === "true");
            setPersonaImbalanceValues(parsedValues);
          }
        } else {
          setError(t("fetch_error_profile"));
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setError(t("fetch_error_generic"));
      }
    };

    fetchProfile();
  }, [t]);

  return (
    <div>
      {profileData ? (
        <div>
          <div className="profile-container">
            <div className="profile-column-persona-imbalance">
              <div className="image-with-octagon">
                <img
                  src="/images/vitruvian_back.webp"
                  alt={t("styling_image_alt")}
                  className="styling-image"
                />

                <Octagon values={personaImbalanceValues} />
              </div>

              <div className="character-container">
                <p>
                  {t("greetings")} <b>{username}</b>!
                </p>
              </div>
            </div>

            <div className="profile-column overall-info">
              <h3>{t("overall_information")}</h3>
              <div className="character-container">
                <p>
                  <strong>{t("main_sensory_channel")}:</strong>{" "}
                  {profileData.main_sensory_channel || t("not_available")}
                </p>
                <p>
                  <strong>{t("asso_disso")}:</strong>{" "}
                  {profileData.asso_disso || t("not_available")}
                </p>
                <p>
                  <strong>{t("personality_type")}:</strong>{" "}
                  {profileData.personality_type || t("not_available")}
                </p>
                <p>
                  <strong>{t("dark_personality_type")}:</strong>{" "}
                  {profileData.dark_personality_type || t("not_available")}
                </p>
              </div>
              <div className="character-container">
                <h4>{t("personal_values")}</h4>
                <ul>
                  {profileData.user_values ? (
                    profileData.user_values
                      .split(",")
                      .map((trait, index) => (
                        <li key={index}>{trait.trim()}</li>
                      ))
                  ) : (
                    <li>{t("not_available")}</li>
                  )}
                </ul>
              </div>
            </div>

            <div className="profile-column character-info">
              <h3>{t("character_information")}</h3>
              <div className="character-container">
                <h4>{t("character_strengths")}</h4>
                <ol>
                  {profileData.character_strengths ? (
                    profileData.character_strengths
                      .split(",")
                      .map((strength, index) => (
                        <li key={index}>{strength.trim()}</li>
                      ))
                  ) : (
                    <li>{t("not_available")}</li>
                  )}
                </ol>
              </div>

              <div className="character-container">
                <h4>{t("character_weaknesses")}</h4>
                <ol>
                  {profileData.character_weaknesses ? (
                    profileData.character_weaknesses
                      .split(",")
                      .map((weakness, index) => (
                        <li key={index}>{weakness.trim()}</li>
                      ))
                  ) : (
                    <li>{t("not_available")}</li>
                  )}
                </ol>
              </div>
            </div>

            <div className="profile-column goals-pains">
              <h3>{t("basic_exercise_resources")}</h3>
              <p>{t("exercise_resources_desc")}</p>
              <div className="character-container">
                <ul>
                  {profileData.user_resources ? (
                    profileData.user_resources
                      .split(",")
                      .map((resource, index) => (
                        <li key={index}>{resource.trim()}</li>
                      ))
                  ) : (
                    <li>{t("not_available")}</li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="home-container">
          <div className="loading">
            <p>{error || t("loading_profile")}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
