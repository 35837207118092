import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionTab } from "primereact/accordion";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import { useCookies } from "react-cookie";
import api from "../../services/api";
import { logOuthandler } from "../../services/authUtils";
import "./Settings.css";
import "./theme.css"; // Tuo mukautettu teema

const Settings = () => {
  const { t, i18n } = useTranslation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [language, setLanguage] = useState(i18n.language);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [analyticsAllowed, setAnalyticsAllowed] = useState(false);
  const [cookieConsent, setCookie] = useCookies([
    "superAppAnalyticsConsentCookie",
  ]);

  useEffect(() => {
    const isAnalyticsAllowed = cookieConsent.superAppAnalyticsConsentCookie;
    setAnalyticsAllowed(isAnalyticsAllowed);
  }, [cookieConsent]);

  const handleDeleteAccount = async () => {
    try {
      const confirmDelete = window.confirm(t("confirm_delete_account"));
      if (!confirmDelete) {
        return;
      }

      const response = await api.delete("/delete-account");

      if (response.data.error) {
        setErrorMessage(response.data.error);
      } else {
        setMessage(
          `${response.data.message}. ${t(
            "account_deleted_redirecting_to_home_shortly"
          )}`
        );
        await logOuthandler();
        setTimeout(() => {
          window.location.href = "/";
        }, 5000);
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      setErrorMessage(t("delete_account_error"));
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setMessage("");

    if (newPassword !== confirmPassword) {
      setErrorMessage(t("passwords_do_not_match"));
      return;
    }

    try {
      const response = await api.post("/change-password", {
        oldPassword,
        newPassword,
      });

      setMessage(response.data.message || t("password_change_success"));
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      console.error("Error changing password:", error);
      setErrorMessage(
        error.response?.data?.error || t("change_password_error")
      );
    }
  };

  const handleDownloadContent = () => {
    // Implement content download logic here
  };

  const handlePrintProfile = () => {
    // Implement print profile logic here
  };

  const handleAnalyticsSwitch = (e) => {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    setAnalyticsAllowed(e.value);
    setCookie("superAppAnalyticsConsentCookie", e.value, {
      path: "/",
      expires,
    });
  };

  const handleCheckboxChange = (e) => {
    setAnalyticsAllowed(e.checked);
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);
    setCookie("superAppAnalyticsConsentCookie", e.checked, {
      path: "/",
      expires,
    });
    console.log("Checkbox analytics allowed:", e.checked);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.value);
    i18n.changeLanguage(e.value);
  };

  const notificationOptions = [
    { label: t("notify_on_app"), value: "app" },
    { label: t("notify_on_email"), value: "email" },
  ];

  const languageOptions = [
    { label: "English", value: "en" },
    { label: "Suomi", value: "fi" },
  ];

  return (
    <div className="settings-container">
      <h2>{t("settings")}</h2>
      <Accordion>
        {/*         <AccordionTab header={t("data")}>
          <Button label={t("download_content")} onClick={handleDownloadContent} />
          <Button label={t("print_profile_page")} onClick={handlePrintProfile} />
        </AccordionTab> */}
        <AccordionTab header={t("change_password")}>
          <form onSubmit={handleChangePassword}>
            <div className="settings-form-group">
              <label>{t("old_password")}</label>
              <Password
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                required
                toggleMask
              />
            </div>
            <div className="settings-form-group">
              <label>{t("new_password")}</label>
              <Password
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                toggleMask
              />
            </div>
            <div className="settings-form-group">
              <label>{t("confirm_new_password")}</label>
              <Password
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                toggleMask
              />
            </div>
            <Button label={t("change_password_button")} type="submit" />
            {message && <p className="settings-message">{message}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </form>
        </AccordionTab>
        {/*         <AccordionTab header={t("email_address")}>
          <div className="settings-form-group">
            <label>{t("email_address")}:</label>
            <InputText value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
        </AccordionTab>
        <AccordionTab header={t("email_notifications")}>
          {notificationOptions.map(option => (
            <div key={option.value} className="settings-form-group">
              <Checkbox inputId={option.value} value={option.value} onChange={(e) => setNotifications([...notifications, e.value])} checked={notifications.includes(option.value)} />
              <label htmlFor={option.value}>{option.label}</label>
            </div>
          ))}
        </AccordionTab> */}
        <AccordionTab header={t("cookies_settings")}>
          <div className="settings-form-group">
            <label>{t("allow_analytics_cookies")}:</label>
            <InputSwitch
              checked={analyticsAllowed}
              onChange={handleAnalyticsSwitch}
            />
          </div>
        </AccordionTab>
        <AccordionTab header={t("change_language")}>
          <Dropdown
            value={language}
            options={languageOptions}
            onChange={handleLanguageChange}
            placeholder={t("select_language")}
          />
        </AccordionTab>
        <AccordionTab header={t("delete_account")}>
          <p className="m-0">{t("delete_account_info")}</p>
          <Button
            label={t("delete_account_button")}
            className="p-button-danger"
            onClick={handleDeleteAccount}
          />
        </AccordionTab>
      </Accordion>
    </div>
  );
};

export default Settings;
