// src/components/LessonEdit.js
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomEditor from "../CustomEditor";
import { Editor } from "primereact/editor";
import api from "../../services/api";
import "../Courses/Course.css";

const LessonEdit = () => {
  const { courseId, lessonId } = useParams(); // Get courseId and lessonId from route
  const [lesson, setLesson] = useState({
    title: "",
    content: "",
    position: 1,
    language: "en",
    image: "",
    image_above: "",
    image_below: "",
  });
  const [error, setError] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchLesson = async () => {
      try {
        const response = await api.get(
          `/courses/${courseId}/lessons/${lessonId}`
        );
        setLesson(response.data);
      } catch (err) {
        console.error("Error fetching lesson details:", err);
        setError(t("fetch_error"));
      }
    };

    fetchLesson();
  }, [courseId, lessonId, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await api.put(`/courses/${courseId}/lessons/${lessonId}`, lesson);

      alert(t("lesson_updated_success"));
      history.push(`/courses/${courseId}`); // Redirect back to course detail page
    } catch (err) {
      console.error("Error updating lesson:", err);
      setError(t("update_error"));
    }
  };

  const handleChange = (field, value) => {
    setLesson((prevLesson) => ({ ...prevLesson, [field]: value }));
  };

  // Define Quill toolbar modules
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline", "strike"],
      ["link", "image", "video"], // Enable video button
    ],
  };

  // Quill formats
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "link",
    "image",
    "video",
  ];

  const editorPtOptions = {
    toolbar: { style: { display: "none" } }, // Keep customization as requested
  };

  if (error) return <p className="error-message">{error}</p>;
  if (!lesson.title) return <p>{t("loading_lesson")}</p>;

  return (
    <div className="create-course-container">
      <h2>{t("edit_lesson_header")}</h2>
      {error && <p className="error-message">{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">{t("lesson_title")}</label>
          <input
            type="text"
            id="title"
            className="form-control"
            value={lesson.title}
            onChange={(e) => handleChange("title", e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="content">{t("lesson_content")}</label>

          <div className="card">
            <Editor
              value={lesson.content}
              onTextChange={(e) => handleChange("content", e.htmlValue)}
              className="course-editor"
              modules={modules}
              formats={formats}
              pt={editorPtOptions} // PT customization
            />
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="position">{t("lesson_position")}</label>
          <input
            type="number"
            id="position"
            className="form-control"
            value={lesson.position}
            onChange={(e) => handleChange("position", parseInt(e.target.value))}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="language">{t("lesson_language")}</label>
          <select
            id="language"
            className="form-control"
            value={lesson.language}
            onChange={(e) => handleChange("language", e.target.value)}
          >
            <option value="en">{t("language_english")}</option>
            <option value="fi">{t("language_finnish")}</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="image">{t("lesson_image")}</label>
          <input
            type="text"
            id="image"
            className="form-control"
            value={lesson.image}
            onChange={(e) => handleChange("image", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="image_above">{t("lesson_image_above")}</label>
          <input
            type="text"
            id="image_above"
            className="form-control"
            value={lesson.image_above}
            onChange={(e) => handleChange("image_above", e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="image_below">{t("lesson_image_below")}</label>
          <input
            type="text"
            id="image_below"
            className="form-control"
            value={lesson.image_below}
            onChange={(e) => handleChange("image_below", e.target.value)}
          />
        </div>

        <button type="submit" className="btn-submit">
          {t("update_lesson_button")}
        </button>
      </form>
    </div>
  );
};

export default LessonEdit;
