import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import logo from "./assets/images/logo.png";
import PageHome from "./components/PageHome";
import PageFeatures from "./components/Features/PageFeatures";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import Profile from "./components/Profile/Profile";
import { logOuthandler } from "./services/authUtils";
import NotFound from "./components/NotFound";
import CreateCourse from "./components/Courses/CreateCourse";
import ValueMapping from "./components/Features/ValueMapping/ValueMapping";
import ImbalanceMapping from "./components/Features/ImbalanceMapping/ImbalanceMapping";
import JournalList from "./components/Journal/JournalList";
import JournalForm from "./components/Journal/JournalForm";
import JournalEdit from "./components/Journal/JournalEdit";
import "./index.css";
import "./i18n";
import { useTranslation } from "react-i18next";
import "flag-icons/css/flag-icons.min.css";
import Settings from "./components/Settings/Settings";
import { Redirect } from "react-router-dom";
import MoodEnergyTracking from "./components/Features/MoodEnergy/MoodEnergyTracking";
import CoursesList from "./components/Courses/CoursesList";
import CourseDetail from "./components/Courses/CourseDetail";
import Lesson from "./components/Lessons/Lesson";
import CourseForm from "./components/Courses/CourseForm";
import CourseEdit from "./components/Courses/CourseEdit";
import LessonForm from "./components/Lessons/LessonForm";
import LessonEdit from "./components/Lessons/LessonEdit";
import RoleBasedComponent from "./components/Auth/RoleBasedComponent";
import RoleBasedRoute from './components/Auth/RoleBasedRoute';
import ResetPassword from "./components/Auth/ResetPassword";
import ForgotPassword from "./components/Auth/ForgotPassword";
import { resetLogoutTimer, handleVisibilityChange, addVisibilityChangeListener, removeVisibilityChangeListener } from './services/sessionManager';
import CookieConsent from './components/Auth/CookieConsent';
import { useCookies } from 'react-cookie';
import Menu from "./components/Navigation/Menu";
import Routes from "./components/Navigation/Routes";

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(true);
  const menuRef = useRef(null); // Reference to the menu element
  const [cookies] = useCookies(["superAppConsentCookie"]);

  const switchLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    setLoggedIn(isLoggedIn);
    setLoading(false);
  }, []);

  // Check session validity
  useEffect(() => {
    // Check session validity immediately
    handleVisibilityChange();
    console.log("App.js: Visibility change listener added");

    resetLogoutTimer();
    addVisibilityChangeListener();

    return () => {
      removeVisibilityChangeListener();
    };
  }, []);
  


  const handleLogout = async () => {
    // Call AuthUtils.js function to handle logout
    await logOuthandler(setLoggedIn, setMenuOpen, setRedirect);
  };


  /* const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setSubmenuOpen(false);
  };

  const toggleSubmenu = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
    setSubmenuOpen(false);
  };

  // Handle clicks outside the menu to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setSubmenuOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); */

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      {redirect && <Redirect to="/login" />}
      <div>
        <Menu 
          loggedIn={loggedIn} 
          handleLogout={handleLogout} 
          switchLanguage={switchLanguage} 
        />
        <Routes loggedIn={loggedIn} setLoggedIn={setLoggedIn} />
        {!cookies.superAppConsentCookie && <CookieConsent />}
      </div>
    </Router>
  );
}


export default App;
