// highlightHTMLContent.js
import React from "react";
import parse from "html-react-parser";

export const highlightHTMLContent = (html, query) => {
  if (!query) {
    // If there's no search term, return parsed HTML as is.
    return parse(html);
  }

  // Escape special characters for use in the regex.
  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const regex = new RegExp(escapedQuery, "gi");

  const options = {
    replace: (domNode) => {
      // Only process text nodes.
      if (domNode.type === "text" && domNode.data) {
        const { data } = domNode;
        if (regex.test(data)) {
          // Split the text node by the search query.
          const parts = data.split(regex);
          const matches = data.match(regex);
          const newNodes = [];
          parts.forEach((part, index) => {
            if (part) {
              newNodes.push(part);
            }
            if (matches && index < matches.length) {
              newNodes.push(
                <span key={index} className="highlight">
                  {matches[index]}
                </span>
              );
            }
          });
          return <>{newNodes}</>;
        }
      }
    },
  };

  return parse(html, options);
};
