import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import api from "../../../services/api";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const MoodEnergyPieChart = () => {
  const [emojiData, setEmojiData] = useState(null);
  const [energyData, setEnergyData] = useState(null);
  const [viewMode, setViewMode] = useState("week");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { t } = useTranslation();

  const isMobile = window.innerWidth <= 768;

  const emojiTextMap = {
    "😊": t("happy"),
    "😢": t("sad"),
    "😡": t("angry"),
    "😨": t("fearful"),
    "😐": t("neutral"),
    "🥳": t("party"),
    "😂": t("laughing"),
    "🤔": t("thinking"),
    "🤢": t("disgusted"),
    "😰": t("anxious"),
    "😟": t("worried"),
    "😴": t("tired"),
    "😇": t("blessed"),
    "🥵": t("exhausted"),
    "😞": t("depressed"),
    "😳": t("ashamed"),
    "😬": t("nervous"),
    "🥴": t("brainfog"),
    "😌": t("relieved"),
    "🤩": t("excited"),
  };

  const energyTextMap = {
    1: t("energy_level_1") + " - 1",
    2: t("energy_level_2") + " - 2",
    3: t("energy_level_3") + " - 3",
    4: t("energy_level_4") + " - 4",
    5: t("energy_level_5") + " - 5",
    6: t("energy_level_6") + " - 6",
    7: t("energy_level_7") + " - 7",
    8: t("energy_level_8") + " - 8",
    9: t("energy_level_9") + " - 9",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.get("/mood-energy/states");
        const states = response.data;

        let filteredStates;

        if (viewMode === "day") {
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate.toDateString() === selectedDate.toDateString();
          });
        } else if (viewMode === "week") {
          const startOfWeek = new Date(selectedDate);
          startOfWeek.setDate(startOfWeek.getDate() - 6); // Edelliset 7 päivää
          startOfWeek.setHours(0, 0, 0, 0);
          const endOfWeek = new Date(selectedDate);
          endOfWeek.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfWeek && stateDate <= endOfWeek;
          });
        } else if (viewMode === "month") {
          const startOfMonth = new Date(selectedDate);
          startOfMonth.setDate(startOfMonth.getDate() - 29); // Edelliset 30 päivää
          startOfMonth.setHours(0, 0, 0, 0);
          const endOfMonth = new Date(selectedDate);
          endOfMonth.setHours(23, 59, 59, 999);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfMonth && stateDate <= endOfMonth;
          });
        } else if (viewMode === "year") {
          const startOfYear = new Date(selectedDate.getFullYear(), 0, 1);
          const endOfYear = new Date(selectedDate.getFullYear(), 11, 31);
          filteredStates = states.filter((state) => {
            const stateDate = new Date(state.created_at);
            return stateDate >= startOfYear && stateDate <= endOfYear;
          });
        }

        const emojiCounts = {};
        const energyCounts = {};

        filteredStates.forEach((state) => {
          const emoji = state.emoji;
          const energy = state.energy;

          if (emojiCounts[emoji]) {
            emojiCounts[emoji]++;
          } else {
            emojiCounts[emoji] = 1;
          }

          if (energyCounts[energy]) {
            energyCounts[energy]++;
          } else {
            energyCounts[energy] = 1;
          }
        });

        setEmojiData({
          labels: Object.keys(emojiCounts).map(
            (emoji) => `${emoji} ${emojiTextMap[emoji]}`
          ),
          datasets: [
            {
              data: Object.values(emojiCounts),
              backgroundColor: Object.keys(emojiCounts).map(
                () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
              ),
            },
          ],
        });

        setEnergyData({
          labels: Object.keys(energyCounts).map(
            (energy) => energyTextMap[energy]
          ),
          datasets: [
            {
              data: Object.values(energyCounts),
              backgroundColor: Object.keys(energyCounts).map(
                () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
              ),
            },
          ],
        });
      } catch (error) {
        console.error("Error fetching mood and energy states:", error);
      }
    };

    fetchData();
  }, [viewMode, selectedDate, t]);

  return (
    <div className="mood-energy-chart-container">
      <div className="date-picker-container">
        <label htmlFor="date-picker">{t("choose_date")}</label>
        <DatePicker
          id="date-picker"
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd.MM.yyyy"
          className="date-picker"
        />
      </div>
      <div className="view-mode-buttons">
        <button
          onClick={() => setViewMode("day")}
          className={viewMode === "day" ? "active" : ""}
        >
          {t("day")}
        </button>
        <button
          onClick={() => setViewMode("week")}
          className={viewMode === "week" ? "active" : ""}
        >
          {t("week")}
        </button>
        <button
          onClick={() => setViewMode("month")}
          className={viewMode === "month" ? "active" : ""}
        >
          {t("month")}
        </button>
        <button
          onClick={() => setViewMode("year")}
          className={viewMode === "year" ? "active" : ""}
        >
          {t("year")}
        </button>
      </div>
      <h2>{t("emoji_distribution")}</h2>
      {emojiData && (
        <Pie
          data={emojiData}
          options={{
            plugins: {
              datalabels: {
                display: true,
                formatter: (value, context) => {
                  const label = context.chart.data.labels[context.dataIndex];
                  const emojiMatch = label.match(/[\p{Emoji_Presentation}\p{Emoji}\u200d]+/gu);
                  const emoji = emojiMatch ? emojiMatch[0] : label; // Jos ei löydy emojia, käytä koko label
                  if (isMobile) {
                    return emoji;
                  }
                  return label;
                },
              },
            },
          }}
        />
      )}
      <h2>{t("energy_distribution")}</h2>
      {energyData && (
        <Pie
          data={energyData}
          options={{
            plugins: {
              datalabels: {
                display: true,
                formatter: (value, context) => {
                  const label = context.chart.data.labels[context.dataIndex];
                  const numMatch = label.match(/\d+/); // Etsi numerot labelista
                  const num = numMatch ? numMatch[0] : '';
                  if (isMobile) {
                    return num;
                  }
                  return label;
                },
              },
            },
          }}
        />
      )}
    </div>
  );
};

export default MoodEnergyPieChart;