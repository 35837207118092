import React from "react";
import { format, isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import CustomTextDisplayer from "../CustomTextDisplayer";
import DOMPurify from "dompurify";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
import "../Journal/Journal.css";
import Highlight from "./Highlight";
import { highlightHTMLContent } from "./HighlightHTMLContent";
import "quill/dist/quill.snow.css"; // Ensure Quill styles are imported

// JournalEntries component to display a list of journal entries
// HOX. This component needs a lot of refinements (deleting unnecessary code)

const JournalEntries = ({ entries, searchQuery, handleDelete, history }) => {
  const { t } = useTranslation();

  /**
   * Helper function to detect if a string is likely HTML
   * (contains at least one <...> tag).
   */
  function isHTMLString(str) {
    if (typeof str !== "string") return false;
    return /<[^>]+>/g.test(str);
  }

  return (
    <div className="journal-entries">
      {entries.length > 0 ? (
        entries.map((entry) => {
          const modifiedDate = entry.modified_at
            ? new Date(entry.modified_at)
            : null;
          const createdDate = entry.created_at
            ? new Date(entry.created_at)
            : null;

          const displayDate = isValid(modifiedDate)
            ? modifiedDate
            : isValid(createdDate)
            ? createdDate
            : null;

          return (
            <div key={entry.id} className="journal-card">
              <h3 className="journal-title">
                {/* Highlight search term in the title */}
                <Highlight text={entry.title} query={searchQuery} />
              </h3>

              <div className="journal-metadata">
                <small className="journal-category">
                  <i className="fas fa-tag"></i> {entry.category}
                </small>
                <small className="journal-date">
                  <i className="fas fa-calendar-alt"></i>{" "}
                  {displayDate
                    ? format(displayDate, "dd/MM/yyyy")
                    : t("unknown_date")}
                </small>
              </div>

              <div className="journal-tags">
                {entry.tags && entry.tags.length > 0 ? (
                  entry.tags.map((tag, index) => (
                    <span key={index} className="journal-tag">
                      <i className="fas fa-hashtag"></i>

                      <Highlight text={tag} query={searchQuery} />
                      {/* {tag} */}
                    </span>
                  ))
                ) : (
                  <small className="journal-no-tags">{t("no_tags")}</small>
                )}
              </div>

              <div className="journal-content">
                {/* 1) Check if entry.content is a Quill Delta: */}
                {entry.content &&
                typeof entry.content === "object" &&
                entry.content.ops
                  ? (() => {
                      //console.log("DEBUG: Detected Quill Delta", entry.content);

                      const converter = new QuillDeltaToHtmlConverter(
                        entry.content.ops,
                        {}
                      );
                      const html = converter.convert();
                      //console.log("DEBUG: Converted HTML is", html);

                      const sanitized = DOMPurify.sanitize(html);
                      //console.log("DEBUG: Sanitized HTML is", sanitized);

                      // Highlight within the HTML content
                      const highlighted = highlightHTMLContent(
                        sanitized,
                        searchQuery
                      );
                      return <div>{highlighted}</div>;

                      // return (
                      //   <div dangerouslySetInnerHTML={{ __html: sanitized }} />
                      // );
                    })()
                  : (() => {
                      // 2) If it's not a Quill Delta, check if content is HTML:
                      if (isHTMLString(entry.content)) {
                        // Treat as HTML and sanitize:
                        const sanitizedHTML = DOMPurify.sanitize(entry.content);
                        //console.log("DEBUG: Detected HTML string",entry.content);

                        //console.log("DEBUG: Sanitized HTML is", sanitizedHTML);

                        // Highlight within the HTML content
                        const highlighted = highlightHTMLContent(
                          sanitizedHTML,
                          searchQuery
                        );
                        return <div>{highlighted}</div>;

                        // return (
                        //   <div
                        //     dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                        //   />
                        // );
                      } else {
                        // 3) Otherwise, treat as plain text:
                        //console.log("DEBUG: Treating as plain text",entry.content);
                        return (
                          <p>
                            <Highlight
                              text={entry.content}
                              query={searchQuery}
                            />
                          </p>
                        );
                      }
                    })()}
              </div>

              <div className="journal-actions">
                <button
                  className="btn-edit"
                  onClick={() => history.push(`/journal/edit/${entry.id}`)}
                >
                  <i className="fas fa-edit"></i> {t("edit")}
                </button>
                <button
                  className="btn-delete"
                  onClick={() => handleDelete(entry.id)}
                >
                  <i className="fas fa-trash-alt"></i> {t("delete")}
                </button>
              </div>
            </div>
          );
        })
      ) : (
        <p>{t("no_entries_available")}</p>
      )}
    </div>
  );
};

export default JournalEntries;
