import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomEditor from "../CustomEditor"; // Import the CustomEditor
import "./Journal.css";
import api from "../../services/api";

const JournalForm = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [category, setCategory] = useState("IRL");
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const history = useHistory();
  const [text, setText] = useState("");

  useEffect(() => {
    if (id) {
      fetchEntry();
    }
  }, [id]);

  const fetchEntry = async () => {
    try {
      const response = await api.get(`/journal/${id}`);
      setTitle(response.data.title);
      setContent(response.data.content);
      setCategory(response.data.category);
      setTags(response.data.tags || []);
    } catch (error) {
      console.error("Error fetching journal entry", error);
      setError(t("fetch_error"));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { title, content, category, tags };

    try {
      if (id) {
        await api.put(`/journal/${id}`, data);
      } else {
        await api.post(`/journal`, data);
      }
      history.push("/journal");
    } catch (error) {
      console.error("Error saving journal entry", error);
      setError(error.response.data.error);
    }
  };

  const handleTagsChange = (e) => {
    setTags(e.target.value.split(",").map((tag) => tag.trim()));
  };

  return (
    <div className="journal-container">
      <h2 className="journal-header">
        {id ? t("edit_journal_entry") : t("create_journal_entry")}
      </h2>
      {error && <p style={{ color: "red" }}>{error}</p>}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">{t("title")}</label>
          <input
            type="text"
            id="title"
            className="form-control"
            placeholder={t("title_placeholder")}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">{t("content")}</label>

          <div className="card journal-editor">
            <CustomEditor
              placeholder={t("content_placeholder")}
              value={content} // Corrected to use the content state
              onTextChange={(e) => setContent(e.htmlValue)}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="category">{t("category")}</label>
          <select
            id="category"
            className="form-control"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="IRL">{t("irl_events")}</option>
            <option value="dreams">{t("dreams")}</option>
            <option value="trance">{t("trance_state")}</option>
            <option value="exercises">{t("exercises")}</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="tags">{t("tags")}</label>
          <input
            type="text"
            id="tags"
            className="form-control"
            placeholder={t("tags_placeholder")}
            value={tags.join(", ")}
            onChange={handleTagsChange}
          />
        </div>
        <button type="submit" className="btn-create-entry">
          {t("save")}
        </button>
      </form>
    </div>
  );
};

export default JournalForm;
